<script setup lang="ts">
import { ref } from "vue";
// import type { Ref } from "vue";
import { useWalletStore } from "@/stores/wallet";
import RoundedContainer from "@/components/RoundedContainer.vue";
import ConnectAccountKeysButton from "@/components/ConnectAccountKeysButton.vue";
import CrossChainTransfer from "@/components/CrossChainTransfer.vue";
import BindAddressButton from "@/components/BindAddressButton.vue";
import StakeDelegateForm from "@/components/StakeDelegateForm.vue";
import StakingRewards from "@/components/StakingRewards.vue";
// @ts-ignore
import ConnectWalletCover from "@/components/ConnectWalletCover.vue";
import IconLock from "@/components/icons/IconLock.vue";
import IconHelp from "@/components/icons/IconHelp.vue";

const activeTab = ref(0);
const wallet = useWalletStore();
</script>

<template>
  <main>
    <div class="flex justify-center">
      <RoundedContainer
        class="w-full md:w-auto overflow-y-scroll max-h-[450px] md:max-h-full relative"
      >
        <ConnectWalletCover />
        <div
          class="px-2 pt-2 flex justify-between bg-white dark:bg-slate-600 sticky -top-2 z-20"
        >
          <span
            class="font-medium text-gray-600 dark:text-gray-100 select-none pb-1"
          >
            Stake
          </span>
          <a
            href="https://www.ftso.au/how-to-guides/2023/11/12/staking-with-ftso-au.html"
            target="_blank"
            class="flex justify-center items-center"
          >
            <span class="text-sm text-gray-400 pr-1">Help</span>
            <div
              class="flex items-center justify-center rounded-full h-5 w-5 border-2 border-gray-300"
            >
              <IconHelp
                class="w-3 text-gray-300 dark:text-gray-300 group-hover:text-black dark:group-hover:text-gray-100"
              />
            </div>
          </a>
        </div>
        <div class="p-2 md:w-[400px]">
          <ConnectAccountKeysButton v-if="!wallet.accountKeys.publicKey" />

          <div
            v-if="wallet.accountKeys.publicKey"
            class="mb-4 p-4 border dark:border-slate-500 border-gray-100 bg-gray-50 dark:bg-slate-500 rounded-xl text-gray-500 dark:text-gray-300 text-sm"
          >
            <div class="pb-2">
              <span class="font-bold text-gray-500 dark:text-gray-200"
                >P Chain Address</span
              >
              <div class="select-all text-xs md:text-sm">
                {{
                  wallet.accountKeys.addressPchain
                    ? "P-" + wallet.accountKeys.addressPchain
                    : "Waiting..."
                }}
              </div>
            </div>
            <div class="pb-2 hidden">
              <span class="font-bold">P Chain Address (Encoded)</span>
              <div class="select-all">
                {{
                  wallet.accountKeys.addressPchainEncoded
                    ? wallet.accountKeys.addressPchainEncoded
                    : "Waiting..."
                }}
              </div>
            </div>
            <span class="font-bold text-gray-500 dark:text-gray-200"
              >Balances</span
            >

            <div class="grid grid-cols-2 h-10">
              <!-- <div class="text-left">
                <p class="font-bold">C Chain Balance</p>
                <span>{{ wallet.balance.native.toLocaleString() }} FLR</span>
              </div> -->
              <div class="relative h">
                <span
                  class="absolute mt-3 ml-8 md:ml-12 text-gray-700 dark:text-gray-100"
                  :class="
                    wallet.balance.native > 1_000_000 ? 'text-base' : 'text-lg'
                  "
                  >{{
                    wallet.balance.native.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}
                  FLR</span
                >
                <span
                  class="absolute mt-[6px] md:mt-0 text-3xl md:text-5xl font-extrabold text-gray-300 dark:text-gray-400 z-10 select-none"
                >
                  C
                </span>
              </div>
              <div class="relative ml-2">
                <div class="absolute mt-1 ml-8 md:ml-12">
                  <span
                    class="text-gray-700 dark:text-gray-100"
                    :class="
                      wallet.balance.native > 1_000_000
                        ? 'text-base'
                        : 'text-lg'
                    "
                    >{{
                      wallet.balance.pnative.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}
                    FLR</span
                  >
                  <div class="flex items-center -ml-1 -mt-[2px] text-xs">
                    <IconLock class="w-4 h-4 text-gray-300 mr-1" />
                    <span
                      >{{
                        wallet.balance.pnativeStaked.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      }}
                      FLR</span
                    >
                  </div>
                </div>

                <span
                  class="absolute text-3xl md:text-5xl mt-[6px] md:mt-0 font-extrabold text-gray-300 dark:text-gray-400 z-10 select-none"
                >
                  P
                </span>
              </div>
              <!-- </div>
              <div class="text-right">
                <p class="font-bold">P Chain Balance</p>
                <span>{{ wallet.balance.pnative.toLocaleString() }} FLR</span>
              </div> -->
            </div>
          </div>

          <div v-if="wallet.accountKeys.publicKey">
            <div
              class="w-full flex flex-row gap-x-2 bg-gray-50 dark:bg-slate-700 rounded-lg py-1 px-1 mb-2"
            >
              <!-- Cross Chain Tab -->
              <div
                @click="activeTab = 0"
                class="basis-1/2 rounded-lg p-1 font-light text-center hover:bg-gray-200 dark:hover:bg-slate-600 cursor-pointer"
                :class="activeTab == 0 ? 'bg-gray-200 dark:bg-slate-600' : ''"
              >
                Cross Chain
              </div>
              <!-- Stake Tab -->
              <div
                @click="activeTab = 1"
                class="basis-1/2 rounded-lg p-1 font-light text-center relative hover:bg-gray-200 hover:dark:bg-slate-600 cursor-pointer"
                :class="activeTab == 1 ? 'bg-gray-200 dark:bg-slate-600' : ''"
              >
                Stake
              </div>
              <!-- Rewards tab -->
              <div
                @click="activeTab = 2"
                class="basis-1/2 rounded-lg p-1 font-light text-center relative hover:bg-gray-200 hover:dark:bg-slate-600 cursor-pointer"
                :class="activeTab == 2 ? 'bg-gray-200 dark:bg-slate-600' : ''"
              >
                Rewards
              </div>
            </div>

            <div class="mb-2" v-if="activeTab == 0">
              <CrossChainTransfer />
            </div>

            <div class="mb-2" v-if="activeTab == 1">
              <BindAddressButton v-if="!wallet.addressBinderBound" />
              <StakeDelegateForm v-else />
            </div>

            <div class="mb-2" v-if="activeTab == 2">
              <StakingRewards />
            </div>
          </div>
        </div>
      </RoundedContainer>
    </div>
  </main>
</template>
