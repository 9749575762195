<script setup lang="ts">
// import { ref } from "vue";
import { ethers } from "ethers";
import { useWalletStore } from "@/stores/wallet";
import {
  publicKeyToPchainEncodedAddressString,
  publicKeyToBech32AddressString,
  publicKeyToEthereumAddressString,
} from "@/helpers/Utils";

// @ts-ignore
import { getPChainAddressBound } from "@/helpers/Flare";

import IconInfo from "@/components/icons/IconInfo.vue";

const wallet = useWalletStore();

const message =
  "You are enabling app.ftso.au to access your accounts PUBLIC key to derive your P-Chain address. Additionally you are acknowledging the risks involved with staking on Flare and the usage of the MetaMask 'eth_sign' functionality. \n\nTERMS OF SERVICE \n\nYou are agreeing to the terms of service found at: https://ftso.au/terms-conditions";

// wallet.accountKeys.publicKey = window.localStorage.getItem("publicKey");
// wallet.accountKeys.addressCchain = window.localStorage.getItem("addressCchain");
// wallet.accountKeys.addressPchain = window.localStorage.getItem("addressPchain");
// wallet.accountKeys.addressPchainEncoded = window.localStorage.getItem(
//   "addressPchainEncoded"
// );

// function clearLocalStorage() {
//   window.localStorage.removeItem("publicKey");
//   window.localStorage.removeItem("addressCchain");
//   window.localStorage.removeItem("addressPchain");
//   window.localStorage.removeItem("addressPchainEncoded");
// }

async function init() {
  if (!wallet.ethereum) throw new Error("Ethereum client not found.");
  // @ts-ignore
  const address = wallet.ethereum?.selectedAddress;
  const signature = await signMessage(address);

  const publicKey = recoverPublicKey(address, message, signature);

  const addressCchain = ethers.utils.getAddress(
    publicKeyToEthereumAddressString(publicKey)
  );
  const addressPchain = publicKeyToBech32AddressString(
    publicKey,
    wallet.network
  );
  const addressPchainEncoded = publicKeyToPchainEncodedAddressString(publicKey);

  // window.localStorage.setItem("publicKey", publicKey);
  // window.localStorage.setItem("addressCchain", addressCchain);
  // window.localStorage.setItem("addressPchain", addressPchain);
  // window.localStorage.setItem("addressPchainEncoded", addressPchainEncoded);

  wallet.accountKeys = {
    publicKey,
    addressPchain,
    addressPchainEncoded,
    addressCchain,
  };

  try {
    const pChainAddressFromAddressBinder = await getPChainAddressBound(wallet);
    if (
      pChainAddressFromAddressBinder !==
      "0x0000000000000000000000000000000000000000"
    )
      wallet.addressBinderBound = true;
  } catch (error) {
    console.log("Error getting bound status", error);
  }

  wallet.connectChainsAndKeys();
  wallet.updateBalancesV2();
}

async function signMessage(address: string): Promise<string> {
  if (!wallet.ethereum) throw new Error("Ethereum client not found.");
  // @ts-ignore
  const signature = await wallet.ethereum.request({
    method: "personal_sign",
    params: [address, message],
  });
  return signature;
}

function recoverPublicKey(
  address: string,
  message: string,
  signature: string
): string {
  const messageHash = ethers.utils.hashMessage(message);
  const recoveredPublicKey = ethers.utils.recoverPublicKey(
    messageHash,
    signature
  );

  // To confirm the signer's address, you can compute the Ethereum address from the recovered public key
  const recoveredAddress = ethers.utils.computeAddress(recoveredPublicKey);

  if (recoveredAddress.toLowerCase() === address.toLowerCase()) {
    return recoveredPublicKey;
  } else {
    throw new Error("Failed to verify signer.");
  }
}
</script>

<template>
  <div>
    <button
      @click="init"
      class="w-full rounded-xl text-center border border-orange-500 bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 capitalize cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
    >
      Connect Public Key
    </button>
    <!-- <button
      @click="clearLocalStorage"
      class="w-full rounded-xl text-center border bg-gray-300 text-white font-semibold py-2 capitalize cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed mt-4"
    >
      Clear Local Storage
    </button> -->
    <!-- <div
      class="flex items-start gap-x-4 my-4 p-4 border dark:border-slate-500 border-gray-100 bg-gray-50 dark:bg-slate-500 rounded-xl text-gray-500 dark:text-gray-300 text-sm"
    > -->
    <div
      class="flex items-start gap-x-4 my-4 p-4 border dark:border-slate-500 border-red-200 bg-red-100 dark:bg-slate-500 rounded-xl"
    >
      <div class="w-6">
        <IconInfo class="w-5 text-orange-600 dark:text-red-500 pt-1" />
      </div>
      <div class="text-gray-800 dark:text-gray-300 text-sm">
        <p>
          Staking with MetaMask requires enabling of "eth_sign" (Settings >
          Advanced > Eth_sign requests).
        </p>
        <p class="pt-2">
          This is considered a less secure method of signing transactions due to
          the inability for MetaMask to read the data you are signing.
        </p>
        <p class="pt-2">
          Read more:
          <a
            class="text-orange-600 hover:text-orange-500 dark:text-orange-500 dark:hover:text-orange-400"
            href="https://go.ftso.au/staking"
            target="_blank"
            >https://go.ftso.au/staking</a
          >
        </p>
      </div>
    </div>
  </div>
</template>
